<template>
  <div class="flex-center-between overflow-hidden radius-10 mrl-20 mb-20 ptb-15 prl-25 bg-gray pos-fix bottom-0 left-0 right-0 z-1 mb:p-15 mb:radius-25 mb:mrl-5 mb:mb-7" v-show="false">
    <span class="color-white">Accept cookies to do something cool but I'm not sure?????????</span>
    <button class="ml-10 cursor-pointer radius-20 font-15 color-white bg-light-blue border-0 outline-0 ptb-8 prl-15" @click="accept">Accept</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    if (!localStorage.getItem('cwa:consent')) {
      this.show = true;
    }
  },
  methods: {
    accept() {
      this.show = false;
      localStorage.setItem('cwa:consent', true);
      this.$router.go();
    }
  }
}
</script>

