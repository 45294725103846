<template>
  <div class="bg-alt-white pt-25 pb-50">
    <div class="codeBlock">
      <p>// Why choose me?</p>
      <p>// Read what others have said!</p>
    </div>
    <div class="comments grid grid-2-col mb-grid-1-col gap-30 mrl-10p mt-25">
      <div v-for="comment in comments" :key="comment.id" class="radius-25 bg-white p-25">
        <div class="flex-center-start">
          <img v-show="comment.img !== ''" :src="comment.img" class="w-40 h-40 mr-10 small-shadow radius-100">
          <span class="font-18">{{ comment.title }}</span>
        </div>
        <p>{{ comment.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import comments from '@/scripts/comments.js';
export default {
  data: function() {
    return {
      comments
    }
  },
}
</script>

<style scoped>
.code {
  width: fit-content;
  margin: 0 auto;
}
</style>
