<template>
  <div class="flex-center-col mtb-25 mrl-10p">
    <span class="code">// Welcome to my site</span>
    <h1 class="text-center mtb-15 font-osBold">My name is Aba</h1>
    <p class="text-center m-0 m-w-650">My mission is to help you become the best programmer you can be. I will help you learn new technologies &amp; prepare you for commercial and personal projects.</p>
    <div class="stats w-full flex-center-between m-w-650 mt-40">
      <div class="flex-center-col">
        <span class="stat-one font-40">20</span>
        <span class="font-14">Years of Experience</span>
      </div>
      <div class="flex-center-col">
        <span class="stat-two font-40">20</span>
        <span class="font-14">Years of Experience</span>
      </div>
      <div class="flex-center-col">
        <span class="stat-three font-40">20</span>
        <span class="font-14">Years of Experience</span>
      </div>
      <div class="flex-center-col">
        <span class="stat-four font-40">20</span>
        <span class="font-14">Years of Experience</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.stat-one { color: rgb(20, 49, 198); }
.stat-two { color: rgb(39, 82, 163); }
.stat-three { color: rgb(63, 124, 118); }
.stat-four { color: rgb(87, 166, 74); }
</style>
