import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/learn',
      name: 'Learn',
      component: () => import(/* webpackChunkName "learn" */ '../views/Learn.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import(/* webpackChunkName "about" */ '../views/About.vue'),
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import(/* webpackChunkName "contact" */ '../views/Contact.vue'),
    },
    {
      path: '/newsletter',
      name: 'Newsletter',
      component: () => import(/* webpackChunkName "newsletter" */ '../views/Newsletter.vue'),
    },
    {
      path: '/learn/csharp',
      name: 'CSharp',
      component: () => import(/* webpackChunkName "csharp" */ '../views/Languages/CSharp/CSharp.vue'),
      children: [
        {
          path: '/learn/csharp',
          name: 'Intro',
          component: () => import(/* webpackChunkName "csharp intro" */ '../views/Languages/CSharp/CSharp/Intro.vue')
        },
        {
          path: '/learn/csharp/1',
          name: 'InstallVisualStudio',
          component: () => import(/* webpackChunkName "csharp InstallVisualStudio" */ '../views/Languages/CSharp/CSharp/InstallVisualStudio.vue')
        },
        {
          path: '/learn/csharp/2',
          name: 'DataTypes',
          component: () => import(/* webpackChunkName "csharp DataTypes" */ '../views/Languages/CSharp/CSharp/DataTypes.vue')
        },
        {
          path: '/learn/csharp/3',
          name: 'ConsoleOutput',
          component: () => import(/* webpackChunkName "csharp ConsoleOutput" */ '../views/Languages/CSharp/CSharp/ConsoleOutput.vue')
        },
        {
          path: '/learn/csharp/4',
          name: 'IfStatement',
          component: () => import(/* webpackChunkName "csharp IfStatement" */ '../views/Languages/CSharp/CSharp/IfStatement.vue')
        },
        {
          path: '/learn/csharp/5',
          name: 'ForLoop',
          component: () => import(/* webpackChunkName "csharp ForLoop" */ '../views/Languages/CSharp/CSharp/ForLoop.vue')
        },
        {
          path: '/learn/csharp/6',
          name: 'WhileLoop',
          component: () => import(/* webpackChunkName "csharp WhileLoop" */ '../views/Languages/CSharp/CSharp/WhileLoop.vue')
        },
        {
          path: '/learn/csharp/7',
          name: 'Functions',
          component: () => import(/* webpackChunkName "csharp Functions" */ '../views/Languages/CSharp/CSharp/Functions.vue')
        },
        {
          path: '/learn/csharp/8',
          name: 'Arrays',
          component: () => import(/* webpackChunkName "csharp Arrays" */ '../views/Languages/CSharp/CSharp/Arrays.vue')
        },
        {
          path: '/learn/csharp/9',
          name: 'Lists',
          component: () => import(/* webpackChunkName "csharp Lists" */ '../views/Languages/CSharp/CSharp/Lists.vue')
        },
        {
          path: '/learn/csharp/10',
          name: 'Structures',
          component: () => import(/* webpackChunkName "csharp Structures" */ '../views/Languages/CSharp/CSharp/Structures.vue')
        },
        {
          path: '/learn/csharp/11',
          name: 'Classes',
          component: () => import(/* webpackChunkName "csharp Classes" */ '../views/Languages/CSharp/CSharp/Classes.vue')
        },
        {
          path: '/learn/csharp/12',
          name: 'Inheritance',
          component: () => import(/* webpackChunkName "csharp Inheritance" */ '../views/Languages/CSharp/CSharp/Inheritance.vue')
        },
        {
          path: '/learn/csharp/13',
          name: 'Abstract',
          component: () => import(/* webpackChunkName "csharp Abstract" */ '../views/Languages/CSharp/CSharp/Abstract.vue')
        },
        {
          path: '/learn/csharp/14',
          name: 'Interface',
          component: () => import(/* webpackChunkName "csharp Interface" */ '../views/Languages/CSharp/CSharp/Interface.vue')
        },
        {
          path: '/learn/csharp/15',
          name: 'Enum',
          component: () => import(/* webpackChunkName "csharp Enum" */ '../views/Languages/CSharp/CSharp/Enum.vue')
        },
        {
          path: '/learn/csharp/16',
          name: 'StaticClasses',
          component: () => import(/* webpackChunkName "csharp StaticClasses" */ '../views/Languages/CSharp/CSharp/StaticClasses.vue')
        },
        {
          path: '/learn/csharp/17',
          name: 'ClassesOverview',
          component: () => import(/* webpackChunkName "csharp ClassesOverview" */ '../views/Languages/CSharp/CSharp/ClassesOverview.vue')
        },
        {
          path: '/learn/csharp/18',
          name: 'Polymorphism',
          component: () => import(/* webpackChunkName "csharp Polymorphism" */ '../views/Languages/CSharp/CSharp/Polymorphism.vue')
        },
        {
          path: '/learn/csharp/19',
          name: 'TryParse',
          component: () => import(/* webpackChunkName "csharp TryParse" */ '../views/Languages/CSharp/CSharp/TryParse.vue')
        },
        {
          path: '/learn/csharp/20',
          name: 'Exceptions',
          component: () => import(/* webpackChunkName "csharp Exceptions" */ '../views/Languages/CSharp/CSharp/Exceptions.vue')
        },
        {
          path: '/learn/csharp/21',
          name: 'Debugging',
          component: () => import(/* webpackChunkName "csharp Debugging" */ '../views/Languages/CSharp/CSharp/Debugging.vue')
        },
        {
          path: '/learn/csharp/22',
          name: 'Regions',
          component: () => import(/* webpackChunkName "csharp Regions" */ '../views/Languages/CSharp/CSharp/Regions.vue')
        },
        {
          path: '/learn/csharp/23',
          name: 'RefParameter',
          component: () => import(/* webpackChunkName "csharp RefParameter" */ '../views/Languages/CSharp/CSharp/RefParameter.vue')
        },
        {
          path: '/learn/csharp/24',
          name: 'OutParameter',
          component: () => import(/* webpackChunkName "csharp OutParameter" */ '../views/Languages/CSharp/CSharp/OutParameter.vue')
        },
        {
          path: '/learn/csharp/25',
          name: 'OptionalParameter',
          component: () => import(/* webpackChunkName "csharp OptionalParameter" */ '../views/Languages/CSharp/CSharp/OptionalParameter.vue')
        },
        {
          path: '/learn/csharp/26',
          name: 'NamedParameter',
          component: () => import(/* webpackChunkName "csharp NamedParameter" */ '../views/Languages/CSharp/CSharp/NamedParameter.vue')
        },
        {
          path: '/learn/csharp/27',
          name: 'ConditionalOperator',
          component: () => import(/* webpackChunkName "csharp ConditionalOperator" */ '../views/Languages/CSharp/CSharp/ConditionalOperator.vue')
        },
        {
          path: '/learn/csharp/28',
          name: 'OverridingToString',
          component: () => import(/* webpackChunkName "csharp OverridingToString" */ '../views/Languages/CSharp/CSharp/OverridingToString.vue')
        }
      ]
    },
    {
      path: '/learn/codewars',
      name: 'Codewars',
      component: () => import(/* webpackChunkName "codewars" */ '../views/Projects/Codewars/Codewars.vue'),
      children: [
        {
          path: '/learn/codewars',
          name: 'PhoneNumber',
          component: () => import(/* webpackChunkName "phone number" */ '../views/Projects/Codewars/Codewars/PhoneNumber.vue'),
        },
        {
          path: '/learn/codewars/rgb-to-hex',
          name: 'RGBHex',
          component: () => import(/* webpackChunkName "rgb hex" */ '../views/Projects/Codewars/Codewars/RGBHex.vue'),
        },
        {
          path: '/learn/codewars/remove-string-spaces',
          name: 'Remove String Spaces',
          component: () => import(/* webpackChunkName "Remove String Spaces" */ '../views/Projects/Codewars/Codewars/StringSpaces.vue'),
        },
        {
          path: '/learn/codewars/vowel-count',
          name: 'Vowel Count',
          component: () => import(/* webpackChunkName "vowel-count" */ '../views/Projects/Codewars/Codewars/VowelCount.vue'),
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})
