export default [
  {
    title: 'Nightmare Studio',
    img: require('@/images/comment4.jpg'),
    desc: 'Hi, I\'ve been watching your videos for a while and I want to tell you that you\'re doing some really good C# tutorials. I have a discord server dedicated to all game developers in Romania in which I have a list of various tutorials related to C# and Unity. In that list I added your youtube channel, I appreciate your work :D'
  },
  {
    title: 'Dead Shades',
    img: require('@/images/comment1.jpg'),
    desc: 'You explained it much better then my course that\'s all about programming.'
  },
  {
    title: 'Warrior',
    img: require('@/images/comment2.jpg'),
    desc: 'Underrated C# tutorial, probably the most useful out there.'
  },
  {
    title: 'Blue Eternity',
    img: require('@/images/comment3.jpg'),
    desc: 'If I ever do become successful in coding I will be ever more grateful to you and try to mention you in every way I can'
  },
];
