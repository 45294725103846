<template>
  <div :class="`pos-rel overflow-hidden ${size} ${mSize}`">
    <img class="w-full h-full" src="@/images/code.jpg">
    <div class="flex-center-col heroOverlay h-full text-center mrl-25">
      <h1 class="m-0">{{ header }}</h1>
      <h3 class="mtb-20 font-osLight">{{ title }}</h3>
      <button v-show="showBtn" @click="goLearn">Start Learning</button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'header',
    'title',
    'showBtn',
    'size',
    'mSize'
  ],
  methods: {
    goLearn() {
      this.$router.push('/learn')
    }
  }
}
</script>

<style scoped>

img {
  object-fit: cover;
  filter: brightness(30%);
}
.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
}
.heroOverlay > button {
  text-decoration: none;
  padding: 12px 30px;
  border-radius: 20px;
  background-color: var(--logo-blue-color);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
</style>
