<template>
  <div>
    <Hero
      header='Expand your knowledge!'
      title="Learn exciting technologies from web, desktop &amp; mobile development, UI / UX and more!"
      showBtn=true
      size='h-450'
      mSize='mb-h-350'
    />
    <Intro />
    <Comments />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Intro from '@/components/Intro.vue'
import Comments from '@/components/Comments.vue'
import { scrollTop } from '@/scripts/helper.js'

export default {
  components: {
    Hero,
    Intro,
    Comments
  },
  mounted: function() {
    document.title = 'Homepage | Code with Aba';
    scrollTop();
  }
}
</script>

<style scoped>
</style>
