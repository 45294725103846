<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Cookie />
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Cookie from '@/components/Cookie.vue'
// import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Cookie,
    // Footer
  }
}
</script>

<style>
body {
  margin: 0;
}
h1, h2, h3, h4, h5, h6, p, span, a, button {
  font-family: popReg;
}
.code {
  background-color: rgb(30, 30, 30);
  color: rgb(87, 166, 74);
  padding: 2px 5px;
  border-radius: 3px;
}
.codeBlock {
  margin: 0 auto;
  width: fit-content;
  background-color: rgb(30, 30, 30);
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
}
.codeBlock > p {
  color: rgb(87, 166, 74);
  margin: 0;
}

.noSelect, a, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.flex-w-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-center-start-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-center-end-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-between-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-center-around-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.flex-center-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.flex-center-evenly-col {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.grid { display: grid; }
.grid-2-col { grid-template-columns: 1fr 1fr; }

.flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-evenly { justify-content: space-evenly; }

.gap-10 { gap: 10px; }
.gap-30 { gap: 30px; }

.m-0 { margin: 0; }
.m-5 { margin: 5px; }
.m-10 { margin: 10px; }
.m-15 { margin: 15px; }
.m-20 { margin: 20px; }
.m-25 { margin: 25px; }

.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-40 { margin-top: 40px; }

.mr-3 { margin-right: 3px; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }

.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-50 { margin-bottom: 50px; }

.mb-o-10 { margin: 0 0 10px 0; }
.mb-o-25 { margin: 0 0 25px 0; }

.ml-0 { margin-left: 0; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }

.mtb-5 { margin-top: 5px; margin-bottom: 5px; }
.mtb-10 { margin-top: 10px; margin-bottom: 10px; }
.mtb-10p { margin-top: 10%; margin-bottom: 10%; }
.mtb-15 { margin-top: 15px; margin-bottom: 15px; }
.mtb-20 { margin-top: 20px; margin-bottom: 20px; }
.mtb-25 { margin-top: 25px; margin-bottom: 25px; }
.mtb-35 { margin-top: 35px; margin-bottom: 35px; }
.mtb-100 { margin-top: 100px; margin-bottom: 100px; }

.mrl-5 { margin-left: 5px; margin-right: 5px; }
.mrl-10 { margin-left: 10px; margin-right: 10px; }
.mrl-10p { margin-left: 10%; margin-right: 10%; }
.mrl-15 { margin-left: 15px; margin-right: 15px; }
.mrl-20 { margin-left: 20px; margin-right: 20px; }
.mrl-25 { margin-left: 25px; margin-right: 25px; }

.p-0 { padding: 0; }
.p-5 { padding: 5px; }
.p-10 { padding: 10px; }
.p-15 { padding: 15px; }
.p-20 { padding: 20px; }
.p-25 { padding: 25px; }
.p-50 { padding: 50px; }

.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px; }
.pt-25 { padding-top: 25px; }

.pr-5 { padding-right: 5px; }
.pr-10 { padding-right: 10px; }
.pr-15 { padding-right: 15px; }
.pr-20 { padding-right: 20px; }

.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-20 { padding-bottom: 20px; }
.pb-50 { padding-bottom: 50px; }

.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-15 { padding-left: 15px; }
.pl-20 { padding-left: 20px; }

.ptb-5 { padding-top: 5px; padding-bottom: 5px; }
.ptb-8 { padding-top: 8px; padding-bottom: 8px; }
.ptb-10 { padding-top: 10px; padding-bottom: 10px; }
.ptb-10p { padding-top: 10%; padding-bottom: 10%; }
.ptb-15 { padding-top: 15px; padding-bottom: 15px; }
.ptb-20 { padding-top: 20px; padding-bottom: 20px; }
.ptb-50 { padding-top: 50px; padding-bottom: 50px; }

.prl-5 { padding-left: 5px; padding-right: 5px; }
.prl-8 { padding-left: 8px; padding-right: 8px; }
.prl-10 { padding-left: 10px; padding-right: 10px; }
.prl-10p { padding-left: 10%; padding-right: 10%; }
.prl-15 { padding-left: 15px; padding-right: 15px; }
.prl-20 { padding-left: 20px; padding-right: 20px; }
.prl-25 { padding-left: 25px; padding-right: 25px; }
.prl-30 { padding-left: 30px; padding-right: 30px; }

.h-full { height: 100%; }
.h-500 { height: 500px; }
.h-450 { height: 450px; }
.h-400 { height: 400px; }
.h-350 { height: 350px; }
.h-60 { height: 60px; }
.h-40 { height: 40px; }

.w-full { width: 100%; }
.w-half { width: 50%; }
.w-500 { width: 500px; }
.w-110 { width: 110px; }
.w-50 { width: 50px; }
.w-40 { width: 40px; }
.w-35 { width: 35px; }
.w-25 { width: 25px; }
.w-10 { width: 10px; }

.m-w-650 { max-width: 650px; }
.m-w-250 { max-width: 250px; }

.pos-rel { position: relative; }
.pos-abs { position: absolute; }
.pos-fix { position: fixed; }

.z-1 { z-index: 1;}

.bottom-0 { bottom: 0; }
.right-0 { right: 0; }
.left-0 { left: 0; }

.color-white { color: white; }
.bg-white { background-color: white; }
.bg-alt-white { background-color: #f5f5f5; }
.bg-gray { background-color: #424851; }
.bg-dark-gray { background-color: rgb(30, 30, 30) !important; }
.bg-light-blue { background-color: #8da9c4; }
.text-center { text-align: center; }

.outline-0 { outline: none; }
.border-0 { border: none; }
.border-white-faint { border: 1px solid rgba(255, 255, 255, 0.5); }
.border-white-top { border-top: 1px solid #eaeaea; }
.border-white-bottom { border-bottom: 1px solid #eaeaea; }

.radius-100 { border-radius: 100px; }
.radius-25 { border-radius: 25px; }
.radius-20 { border-radius: 20px; }
.radius-15 { border-radius: 15px; }
.radius-10 { border-radius: 10px; }
.radius-5 { border-radius: 5px; }

.small-shadow { filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)); }

.font-osReg { font-family: osReg; }
.font-osLight { font-family: osLight; }
.font-osItalic { font-family: osItalic; }
.font-osBold { font-family: osBold; }
.font-popReg { font-family: popReg; }
.font-popLight { font-family: popLight; }
.font-popThin { font-family: popThin; }
.font-popBold { font-family: popBold; }
.font-popSemiBold { font-family: popSemiBold; }

.font-48 { font-size: 48px; }
.font-40 { font-size: 40px; }
.font-32 { font-size: 32px; }
.font-24 { font-size: 24px; }
.font-18 { font-size: 18px; }
.font-15 { font-size: 15px; }
.font-14 { font-size: 14px; }

.overflow-hidden { overflow: hidden; }
.overflow-y-scroll { overflow-y: scroll; }

.cursor-pointer { cursor: pointer; }

.desktopHide { display: none; }

.white-filter { filter: invert(90%) brightness(100%); }
.gray-filter { filter: invert(21%) sepia(41%) saturate(878%) hue-rotate(63deg) brightness(21%) contrast(24%); }

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0px;
  display: inline-block;
  padding: 3px;
  margin-left: 3px;
  transition: all 0.5s;
}
.upArrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
}
.downArrow {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-bottom: 0px;
}

.btn-blue {
  padding: 7px 15px;
  background-color: var(--logo-blue-color);
  border-radius: 20px;
  color: white !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobileHide { display: none; }
  .desktopHide { display: block; }

  .mb-m-0 { margin: 0; }
  .mb-m-25 { margin: 25px !important; }
  .mb-m-15 { margin: 15px !important; }
  .mb-mrl-25 { margin-right: 25px; margin-left: 25px; }
  .mb\:mrl-5 { margin-right: 5px; margin-left: 5px; }
  .mb\:mb-7 { margin-bottom: 7px; }

  .mb\:p-15 { padding: 15px; }

  .mb-prl-30 { padding-left: 30px; padding-right: 30px; }

  .mb-text-center { text-align: center; }

  .mb-justify-center { justify-content: center; }
  .mb-flex-col { flex-direction: column; }
  .mb-grid-1-col { grid-template-columns: 1fr; }

  .mb\:radius-25 { border-radius: 25px; }

  .mb-w-auto { width: auto !important; }
  .mb-w-40 { width: 40px; }

  .mb-h-full { height: 100% !important; }
  .mb-h-350 { height: 350px; }
  .mb-h-375 { height: 375px; }
}

@font-face {
  font-family: osReg;
  src: url(./fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: osLight;
  src: url(./fonts/OpenSans-Light.ttf);
}
@font-face {
  font-family: osItalic;
  src: url(./fonts/OpenSans-Italic.ttf);
}
@font-face {
  font-family: osBold;
  src: url(./fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: popReg;
  src: url(./fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: popLight;
  src: url(./fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: popThin;
  src: url(./fonts/Poppins-Thin.ttf);
}
@font-face {
  font-family: popBold;
  src: url(./fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: popSemiBold;
  src: url(./fonts/Poppins-SemiBold.ttf);
}
:root {
  --logo-blue-color: #428bca;
  --chill-blue-color: rgb(46, 59, 81);
}
</style>
