export function isMobile() {
  return window.matchMedia('(max-width: 768px)').matches;
}

export function returnMedia() {
  return window.matchMedia('(max-width: 768px)');
}

export function scrollTop() {
  window.scrollTo(0, 0);
}