<template>
  <div class="border-white-bottom">
    <div class="flex-center-between h-60 mtb-5 mrl-10p mb-mrl-25">
      <a class="h-full cursor-pointer noSelect" @click="goTo('/')">
        <img class="small-shadow h-full" src="@/images/logo.png">
      </a>
      <div class="links mobileHide">
        <a @click="goTo('/learn')">Learn</a>
        <a href="https://www.youtube.com/coffeencode" target="_blank">YouTube</a>
        <a @click="goTo('/about')">About</a>
        <a @click="goTo('/contact')">Contact</a>
        <a @click="goTo('/newsletter')" class="btn-blue">Newsletter</a>
      </div>
      <img @click="mobileHeader = true" class="w-35 desktopHide gray-filter" src="@/images/menu.svg">
    </div>
    <div class="mobileHeader w-full h-full bg-white flex-center-col" v-show="mobileHeader">
      <img @click="mobileHeader = false" class="w-25 pb-50" src="@/images/cross.svg">
      <div class="flex-center-col">
        <a class="noSelect" @click="goTo('/learn')">Learn</a>
        <a class="noSelect" href="https://www.youtube.com/coffeencode" target="_blank">YouTube</a>
        <a class="noSelect" @click="goTo('/about')">About</a>
        <a class="noSelect" @click="goTo('/contact')">Contact</a>
        <a class="noSelect btn-blue" @click="goTo('/newsletter')">Newsletter</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileHeader: false
    }
  },
  methods: {
    goTo(path) {
      if (window.location.pathname !== path) {
        this.$router.push(path);
      }
      setTimeout(() => { this.mobileHeader = false; }, 100);
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
.links > a, .mobileHeader a {
  color: #707070;
  cursor: pointer;
}
.links > a:hover {
  color: black;
}
.links > a:not(:last-child) {
  margin-right: 20px;
}
.mobileHeader {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  overflow-x: hidden;
}
.mobileHeader a:not(:last-child) {
  margin-bottom: 20px;
}
</style>
